.carousel {
    height: auto;
    overflow: hidden;
    background: #f5f5f5;
    position: relative;
    padding: 175px 20px 20px 20px;
}

.banner {
    position: absolute;
    width: 60%;
    height: 700px;
    right: 0;
    top: 0;
    content: '';
    -webkit-clip-path: polygon(25.7% 0%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(25.7% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.banner:after {
    position: absolute;
    width: 100%;
    height: 700px;
    right: 0;
    top: 0;
    content: '';
    background: #09926e;
    background: -moz-linear-gradient(45deg, #2fe7b6 0%, #182dc3 100%);
    background: -webkit-linear-gradient(45deg, #fd5a5a 0%, #09926e 100%);
    background: linear-gradient(45deg, #3a3a3a 0%, #09926e 100%);
    opacity: .5;
}

.carouselHeading {
    font-size: 50px;
    font-family: "Vidaloka", serif;
    margin: 5px 0px 0px 0px;
}

.carouselContent {
    font-family: "IBM Plex Sans", sans serif !important;
    line-height: 1.7;
    color: #646464
}

.carouselActions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.carouselButton {
    font-family: "IBM Plex Sans", sans serif !important;
    background-color: #09926e !important;
    color: #fff !important;
    border: 2px solid transparent !important;
    padding: 16px 25px !important;
    margin-right: 10px !important;
}

.carouselButton:hover {
    background-color: transparent !important;
    color: #161616 !important;
    border: 2px solid #161616 !important;
}

.vide0Link {
    font-family: "IBM Plex Sans", sans serif;
    font-size: 14px;
    color: #646464 !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
}

.vide0Link:hover {
    color: #fd5a5a !important;
}

.paper1 {
    /* width: 100%; */
    background: rgb(255 255 255);
    padding: 40px;
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 9px;
}

.paperHeading {
    font-family: "Vidaloka", serif !important;
    font-size: 48px !important;
    color: #191919 !important;
    margin: 5px 0px !important;
    font-weight: 500;
}

.singleEventBox {
    position: relative;
    background: #f6f6f6;
    margin-top: 52px;
    margin-left: 30px;
    padding: 30px 30px 30px;
    border-radius: 5px;
}

.singleEventBox .eventContent .eventMeta span {
    font-family: "IBM Plex Sans", sans serif;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
}

.singleEventBox .eventContent .eventDate {
    position: absolute;
    top: 0;
    right: 0;
    width: 55px;
    height: 60px;
    border-radius: 3px;
    background: #fd5a5a;
    text-align: center;
    color: #fff;
    font-size: 27px;
    font-family: 'Vidaloka', serif;
    line-height: 25px;
    padding-top: 7px;
}

.singleEventBox .eventContent h3 {
    font-family: 'Vidaloka', serif;
    margin-bottom: 17px;
    font-size: 27px;
    color: #191919;
}

.singleEventBox .eventContent .eventDate span {
    font-family: "IBM Plex Sans", sans serif;
    display: block;
    font-size: 14px;
    text-transform: uppercase;
}

.singleEventBox .eventContent p {
    font-family: "IBM Plex Sans", sans serif;
    font-size: 16px;
    color: #646464;
    display: inline-block;
    padding-right: 20px;
    padding-bottom: 0px;
    margin-left: 0;
    line-height: 1.7;
}

/* .causesCards {
    padding-left: 6%;
    padding-right: 6%;
} */

.card {
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0px 5px 15px;
    margin-bottom: 50px;
    border-radius: 10px;
    transition: all 0.3s ease;
    /* height: 575px; */
}

.card:hover {
    box-shadow: rgb(0 0 0 / 20%) 1px 5px 15px !important;
    cursor: pointer;
}

.cardContentBox {
    padding: 20px;
    text-align: center;
   
}

.cardContentBox p {
    height: 70px;
}

.cardTitle {
    font-family: 'Vidaloka', serif;
    margin-bottom: 17px;
    font-size: 27px;
    color: #191919;
    border-bottom: 1px solid #fd5a5a;
    padding-bottom: 10px;
    text-align: center;
}

.cardContent {
    font-family: "IBM Plex Sans", sans serif;
    font-size: 16px;
    color: #646464;
    line-height: 1.5;
    text-align: center;
}

.cardButton {
    background-color: #fd5a5a !important;
    color: #fff !important;
    border: 2px solid transparent !important;
    box-shadow: none;
    border-radius: 0px !important;
    padding: 10px 20px !important;
}

.cardButton:hover {
    background-color: transparent !important;
    color: #161616 !important;
    border: 2px solid #161616 !important;
}
