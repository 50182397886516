.contact h3 {
    font-family: 'Montserrat', sans-serif !important;
}

.contact a {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

.contact a:focus {
    outline: none !important;
}

.contact a:hover {
    text-decoration: none;
    color: #09926e;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0px;
}

.contact {
    font-family: 'Montserrat', sans-serif !important;
}

.contact .contact_details {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
}

.contact ul, li {
    margin: 0px;
    list-style-type: none;
}

.contact input {
    outline: none;
    border: none;
}

.contact textarea {
    font-family: 'Montserrat', sans-serif;
    outline: none;
    border: none;
}

.contact textarea:focus, input:focus {
    border-color: transparent !important;
}

.contact input::-webkit-input-placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #999999;
    font-weight: bold;
}

.contact input:-moz-placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #999999;
    font-weight: bold;
}

.contact input::-moz-placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #999999;
    font-weight: bold;
}

.contact input:-ms-input-placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #999999;
    font-weight: bold;
}

.contact textarea::-webkit-input-placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #999999;
    font-weight: bold;
}

.contact textarea:-moz-placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #999999;
    font-weight: bold;
}

.contact textarea::-moz-placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #999999;
    font-weight: bold;
}

.contact textarea:-ms-input-placeholder {
    color: #999999;
}

.contact button {
    outline: none !important;
    border: none;
    background: transparent;
}

.contact button:hover {
    cursor: pointer;
}

.contact iframe {
    border: none !important;
}

.contact .contact1 {
    width: 100%;
    /* min-height: 100%; */
    padding: 15px;
    background: #fff;
    background: -webkit-linear-gradient(left, #fff, #fff);
    background: -o-linear-gradient(left, #fff, #fff);
    background: -moz-linear-gradient(left, #fff, #fff);
    background: linear-gradient(left, #fff, #fff);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.contact .container-contact1 {
    width: 1163px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 90px 130px 88px 148px;
}

.contact .contact1_pic {
    width: 296px;
}

.contact .contact1_pic img {
    max-width: 100%;
}

.contact .contact1_form {
    /* width: 390px; */
}

.contact .back {
    font-family: 'Montserrat', sans-serif !important;
    margin-top: 25px;
    background-color: #e4e2e2;
    border-radius: 10px;
}

.contact .single_div {
    position: relative;
    text-align: center;
    margin: 0 20px;
    padding: 30px;
    border-radius: 3px;
}

.contact .contact1_form_title {
    display: block;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    padding-bottom: 44px;
}

.contact input.input1 {
    height: 50px;
    border-radius: 25px;
    padding: 0 30px;
}

.contact input.input1+.shadow_input1 {
    border-radius: 25px;
}

.contact textarea.input1 {
    min-height: 150px;
    border-radius: 25px;
    padding: 12px 30px;
}

.contact textarea.input1+.shadow_input1 {
    border-radius: 25px;
}

.contact .wrap_input1 {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 20px;
}

.contact .input1 {
    display: block;
    width: 85%;
    background: #e6e6e6;
    font-family: Montserrat-Bold;
    font-size: 15px;
    line-height: 1.5;
    color: #666666;
}

.contact .shadow_input1 {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 0px;
    color: #09926e;
}

.contact .input1:focus+.shadow_input1 {
    -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
    animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 80px 30px;
        opacity: 0;
    }
}

@keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 80px 30px;
        opacity: 0;
    }
}

.contact .container_contact1_form_btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.contact .contact1_form_btn {
    min-width: 193px;
    height: 50px;
    border-radius: 25px;
    background: #09926e;
    font-family: Montserrat-Bold;
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.contact .contact1_form_btn i {
    margin-left: 7px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.contact .contact1_form_btn:hover {
    background: #333333;
}

.contact .contact1_form_btn:hover i {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
}

/*------------------------------------------------------------------
  [ Responsive ]*/

/* @media (max-width: 1200px) {
    .contact1_pic {
        width: 33.5%;
    }
    .contact1_form {
        width: 44%;
    }
}

@media (max-width: 992px) {
    .container-contact1 {
        padding: 90px 80px 88px 90px;
    }
    .contact1_pic {
        width: 35%;
    }
    .contact1_form {
        width: 55%;
    }
}

@media (max-width: 768px) {
    .container-contact1 {
        padding: 90px 80px 88px 80px;
    }
    .contact1_pic {
        display: none;
    }
    .contact1_form {
        width: 100%;
    }
} */

@media (max-width: 576px) {
    .contact .container-contact1 {
        padding: 90px 15px 88px 15px;
    }
    .contact .input1 {
        width: 80%;
    }
}

/*------------------------------------------------------------------
  [ Alert validate ]*/

.contact .validate_input {
    position: relative;
}

.contact .alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: white;
    border: 1px solid #c80000;
    border-radius: 13px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 8px;
    pointer-events: none;
    font-family: Montserrat-Medium;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.contact .alert-validate::after {
    content: "\f06a";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 13px;
}

.contact .alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 992px) {
    .contact .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}