html {
    scroll-behavior: smooth;
}

body {
    font-family: 'IBM Plex Sans', sans-serif;
    /* font-family: 'Montserrat', sans-serif; */
    /* font-family: 'Vidaloka', serif; */
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'vidaloka', serif;
    font-weight: normal;
    color: #191919;
    margin: 0px;
    padding: 0;
    font-style: normal;
}

p {
    font-family: "IBM Plex Sans", sans serif;
}

a {
    font-family: "IBM Plex Sans", sans serif;
}

.fontName {
    font-family: 'vidaloka', serif;
}

.text-center {
    text-align: center !important;
}

.carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: left !important;
    background: transparent !important;
}

.section-title span.span-after {
    font-family: 'vidaloka', serif;
    position: relative;
    /* z-index: 1; */
    font-size: 16px;
    font-weight: 500;
    color: #191919;
    text-transform: uppercase;
    display: inline-block;
    padding-top: 10px;
    margin-bottom: 30px;
}

.section-title span.span-after:after {
    position: absolute;
    width: 2px;
    height: 70px;
    top: 0;
    left: 50%;
    content: '';
    background: #09926e;
    z-index: -1;
}

.section-title h2 {
    font-family: 'vidaloka', serif;
    margin: 0px;
    font-size: 48px;
    color: #191919;
}

.single-work-box .single-work-content .work-meta span, .single-work-box .single-work-content .work-meta span {
    display: block;
    margin-bottom: 20px;
}

.work-area {
    background-color: rgb(248, 247, 247);
}

.single-work-box .single-work-content .work-meta span.mission:before, .single-work-box .single-work-content .work-meta span.goal:before {
    font-family: Flaticon;
    padding-right: 10px;
    color: #ff6464;
    font-size: 20px;
}

.single-work-box .single-work-content .work-meta span.goal:before {
    content: "\f105";
}

.single-work-box .single-work-content .work-meta span.mission:before {
    content: "\f104";
}

.single-work-box {
    transition: .2s;
}

.single-work-box .single-work-content {
    border: 1px solid #e3eaec;
    border-top: none;
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 9px
}

.single-work-box:hover {
    /* box-shadow: 0px 5px 20px #eef1f6; */
    border-bottom: none;
    box-shadow: rgb(0 0 0 / 68%) 0px 4px 9px;
}

.single-work-box .single-work-content {
    border: 1px solid #DDDFE1;
    padding: 0;
}

.single-work-img {
    border-radius: 0;
}

.single-work-box .single-work-content .work-body {
    padding: 25px 30px 40px;
    text-align: center;
}

.single-work-content h3 {
    margin-bottom: 10px;
    font-weight: bold;
}

.single-work-content p {
    margin-bottom: 30px;
}

.single-work-box .work-meta {
    margin-bottom: 20px;
}

.single-work-box .work-meta span {
    display: inline-block;
    margin-right: 10px;
}

.work-area-v4::before {
    display: none;
}

.single-work-box:hover {
    box-shadow: 0px 20px 40px 0px rgba(0, 41, 124, 0.08);
    border: none;
}

.single-work-box:hover .single-work-content {
    border-color: transparent;
}

.single-work-box .single-work-content {
    border: 1px solid #e3eaec;
    border-top: none;
}

.single-work-img {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
}

.single-work-img img {
    display: block;
    width: 100%;
    transition: .3s;
}

.single-work-img:hover img {
    transform: scale(1.1);
}

@media (max-width: 1200px) and (min-width: 992px) {
    .section-title h2 {
        font-size: 38px;
    }
}