.logo{
	text-align: right;
}

.mainMenu ul li {
    display: inline-block;
    position: relative;
}

.mainMenu ul li a {
    display: block;
    color: #444444;
    /* text-transform: uppercase; */
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    padding: 20px 8px;
    opacity: .8;
}

.mainMenu ul>li:hover>a {
    opacity: 1;
}

.dropdown {
    position: relative;
    /* display: flex; */
    align-items: center;
}

.dropdown2 {
    position: relative;
    display: flex;
    align-items: center;
}

.dropdownbutton {
    background-color: #fff;
    color: #444444;
    /* text-transform: uppercase; */
    padding: 16px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    opacity: .8;
}

.dropdownContent {
    display: none;
    opacity: 0;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    line-height: 0%;
    padding: 5px;
    transition: all 0.6s ease;
}

.dropdownContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    transition: all 0.3s ease;
}

.dropdownContent a:hover {
    color: #fff;
    background-color: #09926E;
}

.dropdownbutton:hover {
    opacity: 1;
}

.notifications {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    padding: 0 12px;
    margin-bottom: -3px;
    font-size: 1.2em;
}

.redDot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    position: relative;
    top: -18px;
    left: 22px;
    display: block;
    opacity: 0;
    transition: all 0.5s;
}

.redDot2 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    position: relative;
    top: -31px;
    left: 22px;
    display: block;
    opacity: 0;
    transition: all 0.5s;
}

.dropdownContent2 {
    display: none;
    position: absolute;
    left: -15rem;
    top: 1.2rem;
    background-color: white;
    width: auto;
    min-width: 280px;
    height: auto;
    overflow-y: scroll;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    transition: all 0.6s ease;
}

.dropdownContent2::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 960px) {
    .notifications {
        padding: 12px;
    }
    
    .dropdownContent2 {
        left: -8rem;
        top: 3rem;
    }
}

.dropdownContent2 ul {
    padding: unset;
    font-size: small;
}

.dropdownContent2 ul li {
    color: black;
    padding: 10px 30px;
    text-decoration: none;
    display: block;
    text-align: left;
    transition: all 0.3s ease;
}

.dropdownContent2 ul li:nth-child(even) {
    background-color: rgba(0,0,0,0.033);
}

.animation {
    animation: shake 3.5s ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% { transform: translate(1px, 0px) rotate(0deg); }
    3% { transform: translate(-1px, 0px) rotate(0deg); }
    6% { transform: translate(0px, 0px) rotate(15deg); }
    9% { transform: translate(1px, 0px) rotate(0deg); }
    12% { transform: translate(-1px, 0px) rotate(0deg); }
    15% { transform: translate(0px, 0px) rotate(-15deg); }
    18% { transform: translate(-1px, 0px) rotate(0deg); }
    21% { transform: translate(1px, 0px) rotate(0deg); }
    24% { transform: translate(0px, 0px) rotate(15deg); }
    27% { transform: translate(1px, 0px) rotate(0deg); }
    30% { transform: translate(-1px, 0px) rotate(0deg); }
    33% { transform: translate(0px, 0px) rotate(0deg); }
    100% { transform: translate(0px, 0px) rotate(0deg); }
}

.dropdown:hover .dropdownContent {
    display: block;
    opacity: 1;
}

.show {
    display: block;
}
