 #progressbar {
     margin-bottom: 30px;
     overflow: hidden;
     color: lightgrey;
     text-align: center !important;
 }

 #progressbar li {
     list-style-type: none !important;
     font-size: 15px;
     width: 25%;
     float: left;
     position: relative;
     font-weight: 400;
 }

 #progressbar li.active:before, #progressbar li.active:after {
     background: #09926e;
 }

 #progressbar li:before {
     width: 50px;
     height: 50px;
     line-height: 45px;
     display: block;
     font-size: 20px;
     color: #ffffff;
     background: lightgray;
     border-radius: 50%;
     margin: 0 auto 10px auto;
     padding: 2px;
 }

 #progressbar li:after {
     content: '';
     width: 100%;
     height: 2px;
     background: lightgray;
     position: absolute;
     left: 0;
     top: 25px;
     z-index: -1;
 }

 #progressbar #personal:before {
     font-family: FontAwesome;
     content: "\f13e";
 }

 #progressbar #skills:before {
     font-family: FontAwesome;
     content: "\f007";
 }

 #progressbar #emergency:before {
     font-family: FontAwesome;
     content: "\f1cd";
 }

 #progressbar #image:before {
     font-family: FontAwesome;
     content: "\f030";
 }

 .progress {
     height: 10px;
 }

 .progress {
     height: 10px;
     display: -webkit-box;
     display: -webkit-flex;
     display: -ms-flexbox;
     display: flex;
     overflow: hidden;
     font-size: .75rem;
     line-height: 1rem;
     text-align: center;
     background-color: #eceeef;
     border-radius: .25rem;
 }

 .progress-bar {
     background-color: #09926e;
 }

 .progress-bar-animated {
     -webkit-animation: progress-bar-stripes 1s linear infinite;
     -o-animation: progress-bar-stripes 1s linear infinite;
     animation: progress-bar-stripes 1s linear infinite;
 }

 .progress-bar-striped {
     background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
     background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
     background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
     -webkit-background-size: 1rem 1rem;
     background-size: 1rem 1rem;
 }

 .fontsizee {
     color: gray;
     font-size: large;
     font-weight: 500;
 }

 .container {
     display: block;
     position: relative;
     padding-left: 35px;
     margin-bottom: 12px;
     cursor: pointer;
     font-size: 18px;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
 }

 .volunteer .strange input, .volunteer textarea {
     padding: 8px 15px 8px 15px;
     border: 1px solid #ccc;
     border-radius: 0px;
     margin-bottom: 25px;
     margin-top: 2px;
     width: 100%;
     box-sizing: border-box;
     font-family: montserrat;
     color: #2C3E50;
     background-color: #ECEFF1;
     font-size: 16px;
     letter-spacing: 1px;
 }

 .container input:checked~.radiomark {
     background-color: #F44336;
 }

 .radiomark {
     position: absolute;
     top: 0;
     left: 0;
     height: 25px;
     width: 25px;
     background-color: #eee;
     border-radius: 50%;
 }

 .radiomark:after {
     content: "";
     position: absolute;
     display: none;
 }

 .container input:checked~.radiomark:after {
     display: block;
 }

 .container .radiomark:after {
     top: 9px;
     left: 9px;
     width: 8px;
     height: 8px;
     border-radius: 50%;
     background: white;
 }

 .checkmark {
     position: absolute;
     top: 0;
     left: 0;
     height: 25px;
     width: 25px;
     background-color: #eee;
 }

 .container input:checked ~ .checkmark:after {
    display: block;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

 .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.container input:checked ~ .checkmark {
    background-color: #F44336;
}