.register .radiocontainer {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 16px;
    color: #666;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.register .radiocontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.register .radiocontainer input:checked~.checkmark {
    background-color: #e5e5e5;
}

.register .radiocontainer input:checked~.checkmark:after {
    display: block;
}

.radiocontainer .checkmark:after {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #57b846;
}

.register .checkmark {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #e5e5e5;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
}

.register .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}