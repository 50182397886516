.funds-raising {
    /* background-color: #f8f8f8 !important */
}

.funds-raising .counter-icon {
    position: relative;
    max-height: 55px;
    line-height: 55px;
    margin-bottom: 20px;
    z-index: 1;
}

.counter-icon.bg-1:before {
    background: #ffd5d5;
}

.counter-icon:before {
    position: absolute;
    content: '';
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #ffd5d5;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.funds-raising .counter-icon i {
    font-size: 45px;
    color: #191919;
    padding-left: 25px;
    height: 35px;
}

.flaticon-mortarboard:before {
    /* font-family: FontAwesome; */
    font-family: Flaticon !important;
    content: "\f106";
}

.flaticon-heart:before {
    font-family: Flaticon !important;
    content: "\f103";
}

.flaticon-pill:before {
    font-family: Flaticon !important;
    content: "\f100";
}

.flaticon-book:before {
    font-family: Flaticon !important;
    content: "\f101";
}

/* .funds-raising .counter-text h2:before {
    position: absolute;
    width: 1px;
    height: 50px;
    top: -4px;
    right: 0;
    content: '';
    background: #ddd;
} */

.funds-raising .counter-text {
    display: flex;
    align-items: center;
}

.funds-raising .counter-text h2 {
    margin-right: 15px;
    position: relative;
    padding-right: 15px;
    font-size: 36px;
}

.funds-raising .counter-text h3 {
    margin-right: 15px;
    position: relative;
    padding-right: 15px;
    font-size: 27px;
}

/* .funds-raising .counter-text h2:before {
    position: absolute;
    width: 1px;
    height: 50px;
    top: -4px;
    right: 0;
    content: '';
    background: #ddd;
} */

.funds-raising .counter-text h3 {
    max-width: 120px;
    line-height: 30px;
}

.funds-select .gfield_select {
    background: #fff none repeat scroll 0 0;
    border: 2px solid transparent;
    color: #000;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    padding: 7px 30px;
    text-align: center;
    transition: all 0.3s ease 0s;
    font-size: 16px !important;
}

.funds-select .btn {
    background-color: #191919 !important;
    color: #fff !important;
    width: 100%;
    padding: 10px;
    border-radius: 7px;
}

.help .help-img {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    max-width: 100%;
    height: auto;
}

.help .help-img .image {
    max-width: 100%;
    height: auto;
}

.help .help-img img {
    width: 100%;
    transition: .3s;
}

.help .help-img:hover img {
    transform: scale(1.1) !important;
}

.help .section-title span.span, .section-title span.span-border {
    color: #fd5a5a;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.section-title span.span-border:before {
    display: inline-block;
    content: '';
    vertical-align: middle;
    width: 2px;
    height: 17px;
    background: #fd5a5a;
    margin-right: 10px;
    margin-top: -4px;
}