/* BASIC */

  
  body {
    font-family: 'Poppins', sans-serif;
    height: 100vh;
  }
  
  .login_wrapper_unique a {
    color: #92badd;
    display: inline-block;
    text-decoration: none;
    font-weight: 400;
  }
  
  .login_wrapper_unique h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    margin: 40px 8px 10px 8px;
    color: #cccccc;
  }
  
  /* STRUCTURE */
  
  .login_wrapper_unique .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* width: 100%; */
    min-height: 100%;
    padding: 20px;
  }
  
  .login_wrapper_unique #formContent {
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 50px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 5px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  .login_wrapper_unique #formFooter {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: center;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
  }
  
  /* TABS */
  
  .login_wrapper_unique h2.inactive {
    color: #cccccc;
  }
  
  .login_wrapper_unique h2.active {
    color: #0d0d0d;
    border-bottom: 2px solid #09926e;
  }
  
  /* FORM TYPOGRAPHY*/
  
  .login_wrapper_unique input[type='button'],
  .login_wrapper_unique input[type='submit'],
  .login_wrapper_unique input[type='reset'] {
    background-color: #09926e;
    border: none;
    color: white;
    padding: 15px 70px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-box-shadow: #09926e;
    box-shadow: #09926e;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .login_wrapper_unique input[type='button']:hover,
  .login_wrapper_unique input[type='submit']:hover,
  .login_wrapper_unique input[type='reset']:hover {
    background-color: #fd5a5a;
  }
  
  .login_wrapper_unique input[type='button']:active,
  .login_wrapper_unique input[type='submit']:active,
  .login_wrapper_unique input[type='reset']:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }
  
  .login_wrapper_unique input[type='email'],
  .login_wrapper_unique input[type='password'] {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    /* width: 80%; */
    border: 2px solid #f6f6f6;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
  }
  
  .login_wrapper_unique input[type='email'],
  .login_wrapper_unique input[type='password']:focus {
    background-color: #fff;
    border-bottom: 2px solid #09926e;
  }
  
  .login_wrapper_unique input[type='email'],
  .login_wrapper_unique input[type='password']:placeholder {
    color: #cccccc;
  }
  
  /* ANIMATIONS */
  
  /* Simple CSS3 Fade-in-down Animation */
  .login_wrapper_unique .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  /* Simple CSS3 Fade-in Animation */
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .login_wrapper_unique .fadeIn {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
  
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
  }
  
  .login_wrapper_unique .fadeIn.first {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  
  .login_wrapper_unique .fadeIn.second {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  
  .login_wrapper_unique .fadeIn.third {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }
  
  .login_wrapper_unique .fadeIn.fourth {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
  }
  
  /* Simple CSS3 Fade-in Animation */
  .login_wrapper_unique .underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #09926e;
    content: '';
    transition: width 0.2s;
  }
  
  .login_wrapper_unique .underlineHover:hover {
    color: #0d0d0d;
  }
  
  .login_wrapper_unique .underlineHover:hover:after {
    width: 100%;
  }
  
  /* OTHERS */
  
  *:focus {
    outline: none;
  }
  
  .login_wrapper_unique #icon {
    width: 60%;
  }
  